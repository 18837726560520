import axios from "axios";
import { useQuery } from "react-query";

const useFetchProductReviewsQuery = ({ product_id, limit }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fetchTask = async () => {
    const tasks = await axios.get(
      `${apiUrl}/reviews/${product_id}/web?limit=${limit}`
    );
    return tasks?.data;
  };
  const { data, isLoading, error } = useQuery(
    "useFetchProductReviewsQuery",
    () => fetchTask()
  );
  return {
    data,
    isLoading,
    error,
  };
};
export default useFetchProductReviewsQuery;
