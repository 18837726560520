import styled from "styled-components";
import StarRating from "./StarRating";
import Button from "react-bootstrap/Button";
import { AiOutlineShopping } from "react-icons/ai";
import { APP_URL } from "../../constants/url";
import dp from "../../images/dp_placeholder.png";

const StyledReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  margin-top: 10px;
  width: 250px;
  margin-right: 10px;
`;

const ReviewCardHeader = styled.div`
  margin-bottom: 5px;
`;

const ProfilePicture = styled.div`
  & > img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
`;

const Username = styled.span`
  font-size: 11px;
  font-weight: 600;
  padding-left: 5px;
`;

const ReviewCardBody = styled.div`
  margin-top: 10px;
  width: 210px;
  border-radius: 15px;
  padding: 10px;
  border: 1px solid #d5d5d5;
  display: flex;
  flex-direction: row;
`;

const ProductPicture = styled.div`
  & > img {
    width: 50px;
    height: 50px;
  }
`;

const ProductDescription = styled.div`
  font-size: 12px;
`;

const ProductButtons = styled.div`
  padding-top: 10px;
`;

const ProductName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const ProductVariantColor = styled.div``;

const ReviewCardFooter = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const RatingRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const HolyGrails = styled.span`
  color: #636366;
  padding-left: 15px;
  padding-top: 3px;
  font-weight: 700;
  font-size: 12px;
`;

const Repurchased = styled.span`
  color: #636366;
  padding-left: 15px;
  padding-top: 3px;
  font-weight: 700;
  font-size: 12px;
`;

const ReviewText = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ReviewImages = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  overflow: hidden;
`;

const ReviewImage = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 5px;
`;

const ReviewButton = styled(Button)`
  color: white;
  background-color: #ff2d55;
  border: 1px solid;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 12px 12px 12px 12px;
  font-size: 12px;
`;

const ShoppingButton = styled(Button)`
  border: 1px solid;
  margin-right: 2px;
  background-color: white;
  cursor: pointer;
  border-color: #ff2d55;
  color: #ff2d55;
  border-radius: 12px 12px 12px 12px;
`;

const ReviewCard = ({ review }) => (
  <StyledReviewCard>
    <ReviewCardHeader>
      <ProfilePicture>
        <img src={review?.user?.image_url || dp} alt="non" />
        <Username>{review?.user?.username}</Username>
      </ProfilePicture>
    </ReviewCardHeader>
    <ReviewCardBody>
      <ProductPicture>
        <img src={review?.product?.images[0]} alt="no imgs" />
      </ProductPicture>
      <ProductDescription>
        <ProductName>{review?.product?.name}</ProductName>
        <ProductVariantColor>{`Black`}</ProductVariantColor>
        <ProductButtons>
          <ShoppingButton
            className="shopping-button"
            target="_blank"
            href={APP_URL}
            variant="danger"
          >
            <AiOutlineShopping />
          </ShoppingButton>
          <ReviewButton
            className="review-button"
            target="_blank"
            href={APP_URL}
            variant="danger"
          >
            Review
          </ReviewButton>
        </ProductButtons>
      </ProductDescription>
    </ReviewCardBody>
    <ReviewCardFooter>
      <RatingRow>
        <StarRating rating={4} />
        {review?.is_holy_grail && <HolyGrails>HG</HolyGrails>}
        {review?.is_repurchased && <Repurchased>Repurchased</Repurchased>}
      </RatingRow>
      <ReviewText>{review?.text}</ReviewText>
      <ReviewImages>
        {review?.images?.map((image, index) => (
          <ReviewImage key={index} src={image} alt="no img" />
        ))}
      </ReviewImages>
    </ReviewCardFooter>
  </StyledReviewCard>
);

export default ReviewCard;
